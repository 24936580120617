<template>
  <b-modal id="reject-dispatch" :title="title" size="lg" ok-title="Reject" ok-variant="danger" 
    ref="modal" @ok="handleOk" @cancel="onReset" 
    :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" 
    :no-close-on-backdrop="true" hide-header-close>
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

    <b-container fluid>
      <div>
        <b-row class="mb-3">
					<b-col sm="12">
						<i class="icon-info"></i>&nbsp; To  <b-badge pill variant="danger">REJECT</b-badge> dispatch <span class="numFont"><strong>{{ dispatchNo }}</strong></span>, 
            please complete the details below:
					</b-col>
				</b-row>

        <b-card>
          <b-row class="mt-0 mb-2">
            <b-col sm="8">
              <b>PRIMARY INFORMATION</b>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col lg="6" md="6" sm="12" class="my-1">
              <b-form-group label="SOURCE" label-class="font-weight-bold pt-0">
                {{ sourceCompany }}
              </b-form-group>
              <b-form-group label="DESTINATION" label-class="font-weight-bold pt-0">
                {{ destinationCompany }}
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="my-1">
              <b-form-group label="REMARKS" label-class="font-weight-bold pt-0">
                <span class="truncate-text">
                  <truncate collapsed-text-class="collapsed" clamp="Show More" less="Show Less"
                    type="html" :text="breakRemarks(remarks, 50)" :length="100" />
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row>
            <b-col lg="6" md="6" sm="12">
              <b-form-group label="DOCUMENTS" label-for="Proof of Receipt"
                label-class="font-weight-bold pt-0" />

              <div v-show="noOfDocuments >= 1">
                <b-row class="mt-4" no-gutters>
                  <b-col sm="11">
                    <b-form-group label="Document 1" label-for="Document 1"
                      description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
                      <b-form-file id="Document 1" v-model="files[0]" placeholder="Choose image"
                        ref="document-1" accept="image/png, image/jpeg, image/jpg" @change="onSelectDocument($event, 0)">
                        <template slot="file-name" slot-scope="{ names }">
                          <b-badge variant="dark">{{ names[0] }}</b-badge>
                        </template>
                      </b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Preview -->
                <div class="document-file" v-if="isNonNullDocument(0)">
                  <i @click="onShowDocument(selDocuments[0])">
                    {{ selDocuments[0].name }}
                  </i>
                  <em @click="onResetDocument(0)" class="fa fa-times-circle"></em>
                </div>
              </div>

              <div v-show="noOfDocuments >= 2">
                <b-row class="mt-4" no-gutters>
                  <b-col sm="11">
                    <b-form-group label="Document 2" label-for="Document 2"
                      description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
                      <b-form-file id="Document 2" v-model="files[1]" placeholder="Choose image"
                        ref="document-2" accept="image/png, image/jpeg, image/jpg" @change="onSelectDocument($event, 1)">
                        <template slot="file-name" slot-scope="{ names }">
                          <b-badge variant="dark">{{ names[0] }}</b-badge>
                        </template>
                      </b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Preview -->
                <div class="document-file" v-if="isNonNullDocument(1)">
                  <i @click="onShowDocument(selDocuments[1])">
                    {{ selDocuments[1].name }}
                  </i>
                  <em @click="onResetDocument(1)" class="fa fa-times-circle"></em>
                </div>
              </div>

              <div v-show="noOfDocuments >= 3">
                <b-row class="mt-4" no-gutters>
                  <b-col sm="11">
                    <b-form-group label="Document 3" label-for="Document 3"
                      description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
                      <b-form-file id="Document 3" v-model="files[2]" placeholder="Choose image"
                        ref="document-3" accept="image/png, image/jpeg, image/jpg" @change="onSelectDocument($event, 2)">
                        <template slot="file-name" slot-scope="{ names }">
                          <b-badge variant="dark">{{ names[0] }}</b-badge>
                        </template>
                      </b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Preview -->
                <div class="document-file" v-if="isNonNullDocument(2)">
                  <i @click="onShowDocument(selDocuments[2])">
                    {{ selDocuments[2].name }}
                  </i>
                  <em @click="onResetDocument(2)" class="fa fa-times-circle"></em>
                </div>
              </div>

              <b-button class="add-document" variant="primary" @click="onAddDocument">
                Add Document
              </b-button>
            </b-col>
            <b-col lg="6" md="6" sm="12">
              <b-form-group label="REMARKS" label-for="Remarks" description label-class="font-weight-bold pt-0">
                <b-form-textarea name="Remarks" type="text" v-model="proofOfReceipt.remarks" maxlength="200"
                  v-validate="getValidationParam(true, remarksRegex)" :rows="3" placeholder="Remarks" />
                <span v-show="errors.has('Remarks')" class="help-block">
                  {{ errors.first("Remarks") }}
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
// Utils
import { DateUtil } from "@/utils/dateutil";
import { DispatchUtil } from "@/utils/dispatchUtil";
import { FileUtil } from "@/utils/fileUtil";

// API
import dispatchApi from "@/api/dispatchApi";

// Others
import config from "@/config/env-constants";
import EventBus from "@/shared/event-bus";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { storage } from "@/config/firebase";
import truncate from "vue-truncate-collapsed";
import _ from "lodash";

export default {
  name: "reject-dispatch",
  props: {
    loggedUser: {
      type: Object,
      required: true,
    },
  },
  components: {
    Loading,
    truncate,
  },
  data() {
    return {
      selDispatch: {},
      selAssets: {},
      proofOfReceipt: {
        images: [],
        recipientName: "",
        remarks: "",
      },

      selDocuments: [null, null, null],
      files: [null, null, null],
      noOfDocuments: 1,

      // Check for loader
      isLoading: false,
    };
  },
  computed: {
    title() {
      return "Reject Dispatch";
    },
    disableConfirmButtons() {
      return this.isLoading;
    },
    dispatchNo() {
      let dispatch = this.selDispatch ? this.selDispatch : {};
      return dispatch.dispatchNo ? dispatch.dispatchNo : '-';
    },
    sourceCompany() {
      if (!_.isEmpty(this.selDispatch)) {
        return DispatchUtil.getCompanyLocationDisplay(this.selDispatch.source);
      }
      return "";
    },
    destinationCompany() {
      if (!_.isEmpty(this.selDispatch)) {
        return DispatchUtil.getCompanyLocationDisplay(this.selDispatch.destination);
      }
      return "";
    },
    remarks() {
      return this.selDispatch.notes ? this.selDispatch.notes : "-";
    },
    remarksRegex() {
      return config.remarksRegex;
    },
  },
  mounted() {
    EventBus.$on("onRejectDispatch", (dispatch) => {
      this.onReset(dispatch);
    });
  },
  methods: {
    
    getValidationParam(isRequired, regex) {
      return {
        required: isRequired,
        regex: regex,
      };
    },

    async handleOk(evt) {
      // Prevent modal from closing
      evt.preventDefault();

      // show loading indicator
      this.isLoading = true;

      this.processForm();

      let isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$toaster.warning("Please address the field/s with invalid input");
        // hide loading indicator
        this.isLoading = false;
        return;
      } else if (!this.hasUploadedDocs()) {
        this.$toaster.warning("Please upload at least 1 supporting document to proceed.");
        // hide loading indicator
        this.isLoading = false;
        return;
      } else if (this.hasInvalidDocs()) {
        this.$toaster.warning("Please check if all documents uploaded have a file type of PNG or JPG only.");
        // hide loading indicator
        this.isLoading = false;
        return;
      }

      await this.handleSubmit();
    },
    processForm() {
      const assets = this.selDispatch.assets;
      for (const asset of assets) {
        const selAsset = this.selAssets[asset.assetTypeId];
        asset.actualQuantity = selAsset.actualQuantity;
      }
    },
    validateActualQuantity() {
      let assets = this.selDispatch.assets;
      for (const asset of assets) {
        if (asset.actualQuantity === 0) {
          return false;
        }
        if (asset.actualQuantity > asset.expectedQuantity || asset.actualQuantity < 0) {
          return false;
        }
      }
      return true;
    },
    hasUploadedDocs() {
      let uploadedDocs = _.filter(this.selDocuments, (document) => {
        return document && !_.isEmpty(document);
      });
      return _.size(uploadedDocs) >= 1;
    },
    hasInvalidDocs() {
      let invalidDocs = _.filter(this.selDocuments, document => {
        return document !== null && document instanceof File;
      });
      return !_.isEmpty(invalidDocs);
    },

    getDispatchObj() {
      this.proofOfReceipt.recipientName = this.loggedUser.firstName + ' ' + this.loggedUser.lastName;
      this.proofOfReceipt.images = [];

      for (const document of this.selDocuments) {
        if (document && !_.isEmpty(document)) {
          let documentObj = document;
          if (documentObj.isNew) {
            documentObj = {
              name: document.name,
              url: document.url,
              geoaddress: { latitude: 0.0, longitude: 0.0 },
            };
          }
          this.proofOfReceipt.images.push(documentObj);
        } else {
          this.proofOfReceipt.images.push(null);
        }
      }

      let dispatch = { ...this.selDispatch };
      dispatch.proofOfReceipt = this.proofOfReceipt;

      // timestamps
      dispatch.dateUpdated = DateUtil.getCurrentTimestamp();
      dispatch.updatedBy = this.loggedUser.id;
      dispatch.dateRejected = DateUtil.getCurrentTimestamp();
      dispatch.rejectedBy = this.loggedUser.id;

      return dispatch;
    },
    async handleSubmit() {
      try {
        // show loading indicator
        this.isLoading = true;

        let dispatch = this.getDispatchObj();
        let { data } = await dispatchApi.rejectDispatch(dispatch, this.loggedUser.id, DateUtil.getCurrentTimestamp());

        if (data.isSuccess) {
          // upload documents
          await this.uploadDocuments(data.dispatch);

          this.$toaster.success(`Dispatch "${this.dispatchNo}" was successfully rejected.`);
          EventBus.$emit("onCloseReceiveDispatch", data.dispatch);
          this.$refs.modal.hide();

        } else {
          this.$toaster.warning(data.message);
        }
      } catch (error) {
        this.$toaster.error(`Error rejecting dispatch ${this.dispatchNo}. Please try again.`);
      } finally {
        // hide loading indicator
        this.isLoading = false;
      }
    },

    // Documents
    async uploadDocuments(dispatch) {
      for (let i = 0; i < this.selDocuments.length; i++) {

        const document = this.selDocuments[i];
        if (document && document.isNew && document.file instanceof File) {

          const result = await this.firebaseUpload(document);
          if (result.name && result.url) {
            dispatch.proofOfReceipt.images[i].url = result.url;
            await dispatchApi.saveDispatch(dispatch, this.loggedUser.id, DateUtil.getCurrentTimestamp());
          }
        }
      }
    },
    async firebaseUpload(document) {
      return new Promise((resolve, reject) => {
        let storageRef = storage.ref(`${document.fbStoragePath}/${document.name}`);
        let task = storageRef.put(document.file);

        task.on(
          "state_changed",
          () => {
            // let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            reject(error);
          },
          () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve({
                name: document.name,
                url: downloadURL,
              });
            });
          }
        );
      });
    },
    onShowDocument(doc) {
      let url = doc.url;
      let fileName = doc.name;
      EventBus.$emit("onSelectImageView", { url: url, name: fileName });
      this.$bvModal.show("image-view-dialog");
    },
    onSelectDocument(evt, id) {
      const file = evt.target.files[0];

      if (!FileUtil.isValidImgFileType(file)) {
        this.$toaster.error("Invalid File Type: Please import a file in PNG or JPEG format.");
        return;
      }

      const vm = this;
      const url = URL.createObjectURL(file);
      let dimensions = { w: 0, h: 0 };

      const image = new Image();
      image.onload = function () {
        dimensions.w = image.width;
        dimensions.h = image.height;

        if (dimensions.w > 1000 || dimensions.h > 1000) {
          vm.$toaster.warning("The supporting document width and height shouldn't be greater than 1000 pixels.");
        } else {
          vm.$set(vm.selDocuments, id, {
            name: `POR_${vm.selDispatch.dispatchId}_${id}.jpg`,
            url: url,
            file: file,
            fbStoragePath: "images/proofOfReceipt",
            isNew: true,
          });
        }
      };
      image.src = url;
    },
    onAddDocument() {
      if (this.noOfDocuments < 3) {
        this.noOfDocuments++;
      } else if (this.noOfDocuments === 3) {
        this.$toaster.warning("You can only add up to 3 documents only");
      }
    },
    onResetDocument(id) {
      this.$set(this.selDocuments, id, null);
      this.$set(this.files, id, null);
    },
    isNonNullDocument(id) {
      return this.selDocuments[id] && !_.isEmpty(this.selDocuments[id]);
    },

    onReset(dispatch) {
      this.resetFields();

      if (!_.isEmpty(dispatch)) {
        this.selDispatch = DispatchUtil.cleanupFields(dispatch);
        for (const asset of this.selDispatch.assets) {
          // reset actual quantity value
          asset.actualQuantity = 0;
          this.$set(this.selAssets, asset.assetTypeId, { ...asset });
        }
      }

      // reset validation
      this.isLoading = false;
      this.$validator.reset();
      this.errors.clear();
    },
    resetFields() {
      // reset fields
      this.selAssets = {};
      this.proofOfReceipt = {
        images: [],
        recipientName: "",
        remarks: "",
      };
      this.selDocuments = [null, null, null];
      this.files = [null, null, null];
      this.noOfDocuments = 1;
    },

    // UTILS
    breakRemarks(remarks, length) {
      return remarks.length > length
        ? remarks.replace(new RegExp(`([^\\s]{${length}})`, "g"), "$1<br>")
        : remarks;
    },
  },

  beforeDestroy() {
    EventBus.$off("onRejectDispatch");
  },
};
</script>

<style scoped>
.scrollable-container {
  max-height: 400px;
  /* Set the desired maximum height */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.images-label {
  color: #f18f01 !important;
  font-size: 12px;
  font-style: italic;
}

.document-file {
  font-weight: bold;
  margin-top: -1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.document-file i {
  color: #E48F01;
  margin-right: 0.5em;
}

.delete-document {
  margin-top: 32px;
  margin-left: 5px;
}
</style>
