<template>
    <b-modal id="classify-dispatch-assets" size="lg" :title="title" ok-title="Save" ref="modal" @ok="handleOk"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-form @submit.stop.prevent="handleSubmit" novalidate>
            <b-container fluid>
                <div>
                    <b-tabs>
                        <b-tab title="Primary Details" active>

                            <b-row class="my-3">
                                <b-col lg="6" md="6" sm="12">
                                    <b-form-group label="Dispatch No." label-class="font-weight-bold pt-0">
                                        <span class="numFont">{{ dispatchNo }}</span>
                                    </b-form-group>

                                    <b-form-group label="Area" label-for="Area" label-class="font-weight-bold pt-0">
                                        <b-form-radio v-model="form.area" :value="selDispatch.source">
                                            Source:&nbsp;
                                            <span>
                                                <i class="fa fa-map-marker"></i>
                                                <i>{{ getSource() }}</i>
                                            </span>
                                        </b-form-radio>
                                        <b-form-radio class="mt-2" v-model="form.area" :value="selDispatch.destination">
                                            Destination:&nbsp;
                                            <span>
                                                <i class="fa fa-map-marker"></i>
                                                <i>{{ getDestination() }}</i>
                                            </span>
                                        </b-form-radio>
                                    </b-form-group>

                                    <b-form-group class="mt-4" label="Remarks" label-for="Remarks" description
                                        label-class="font-weight-bold pt-0">
                                        <b-form-textarea name="Remarks" type="text" v-model="form.remarks"
                                            maxlength="200" v-validate="getValidationParam(true, remarksRegex)"
                                            :rows="3" placeholder="Remarks" />
                                        <span v-show="errors.has('Remarks')" class="help-block">
                                            {{ errors.first('Remarks') }}
                                        </span>
                                    </b-form-group>
                                </b-col>

                                <b-col lg="6" md="6" sm="12">
                                    <b-form-group label="Supporting Documents" label-for="Supporting Documents"
                                        label-class="font-weight-bold pt-0" />

                                    <div v-show="noOfDocuments >= 1">
                                        <b-row class="mt-4" no-gutters>
                                            <b-col sm="11">
                                                <b-form-group label="Document 1" label-for="Document 1"
                                                    description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
                                                    <b-form-file id="Document 1" v-model="files[0]"
                                                        placeholder="Choose image" ref="document-1" accept="image/png, image/jpeg, image/jpg"
                                                        @change="onSelectDocument($event, 0)">
                                                        
                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="dark">{{ names[0] }}</b-badge>
                                                        </template>
                                                    </b-form-file>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <!-- Preview -->
                                        <div class="document-file" v-if="isNonNullDocument(0)">
                                            <i @click="onShowDocument(selDocuments[0])">
                                                {{ selDocuments[0].name }}
                                            </i>
                                            <em @click="onResetDocument(0)" class="fa fa-times-circle"></em>
                                        </div>
                                    </div>

                                    <div v-show="noOfDocuments >= 2">
                                        <b-row class="mt-4" no-gutters>
                                            <b-col sm="11">
                                                <b-form-group label="Document 2" label-for="Document 2"
                                                    description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
                                                    <b-form-file id="Document 2" v-model="files[1]"
                                                        placeholder="Choose image" ref="document-2" accept="image/png, image/jpeg, image/jpg"
                                                        @change="onSelectDocument($event, 1)">

                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="dark">{{ names[0] }}</b-badge>
                                                        </template>
                                                    </b-form-file>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <!-- Preview -->
                                        <div class="document-file" v-if="isNonNullDocument(1)">
                                            <i @click="onShowDocument(selDocuments[1])">
                                                {{ selDocuments[1].name }}
                                            </i>
                                            <em @click="onResetDocument(1)" class="fa fa-times-circle"></em>
                                        </div>
                                    </div>

                                    <div v-show="noOfDocuments >= 3">
                                        <b-row class="mt-4" no-gutters>
                                            <b-col sm="11">
                                                <b-form-group label="Document 3" label-for="Document 3"
                                                    description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
                                                    <b-form-file id="Document 3" v-model="files[2]"
                                                        placeholder="Choose image" ref="document-3" accept="image/png, image/jpeg, image/jpg"
                                                        @change="onSelectDocument($event, 2)">

                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="dark">{{ names[0] }}</b-badge>
                                                        </template>
                                                    </b-form-file>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <!-- Preview -->
                                        <div class="document-file" v-if="isNonNullDocument(2)">
                                            <i @click="onShowDocument(selDocuments[2])">
                                                {{ selDocuments[2].name }}
                                            </i>
                                            <em @click="onResetDocument(2)" class="fa fa-times-circle"></em>
                                        </div>
                                    </div>

                                    <b-button class="add-document" variant="primary" @click="onAddDocument">
                                        Add Document
                                    </b-button>
                                </b-col>
                            </b-row>

                        </b-tab>

                        <b-tab title="Classification">
                            <b-row class="mt-2 mb-4" no-gutters>
                                <b-col lg="6" md="6" sm="12">
                                    <b-form-group label="Select Condition" label-for="Select Condition"
                                        label-class="font-weight-bold pt-0"
                                        description="Select additional condition(s) under this asset type">
                                        <v-select id="select-condition" name="Select Condition" class="style-chooser"
                                            label="text" v-model="selCondition" :options="conditionOptions"
                                            :reduce="(condition) => condition.value">

                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching">
                                                    No results found for
                                                    <em>
                                                        <strong>{{ search }}</strong>
                                                    </em>
                                                </template>
                                                <em :style="{ opacity: 0.5 }" v-else>
                                                    Start typing to search for a asset type
                                                </em>
                                            </template>
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="1">
                                    <b-button class="add-condition" variant="primary"
                                        @click="onAddCondition">Add</b-button>
                                </b-col>
                            </b-row>

                            <b-row class="mt-2">
                                <b-col sm="2">
                                    <b-form-group>
                                        <span class="label-header">Condition</span>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="4">
                                    <b-form-group>
                                        <span class="label-header">Handling</span>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-group>
                                        <span class="label-header">Quantity</span>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                    <b-form-group>
                                        <span class="label-header">Actions</span>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div v-if="isConditionNonNull()">
                                <b-row v-for="(condition, key) in selConditions" :key="key">
                                    <b-col sm="2">
                                        <b-form-group v-b-tooltip.hover :title="selConditions[key].description">
                                            <span class="orange-txt">{{ selConditions[key].condition }}</span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="4">
                                        <b-form-group>
                                            <b-form-select id="handling" name="Handling"
                                                v-model="selConditions[key].handling" :options="handlingOptions"
                                                :reduce="(condition) => condition.value" required />
                                            <span v-show="errors.has('Handling')" class="help-block">
                                                {{ errors.first('Handling') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-form-group>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <button class="btn btn-outline-secondary decrement-button"
                                                        type="button" @click="onIncrement(key, -1)">-</button>
                                                </div>
                                                <b-form-input id="quantity" name="Quantity" type="number"
                                                    v-model="selConditions[key].quantity"
                                                    v-validate="'required|min_value:0'" :min="1"
                                                    class="text-center numFont" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary increment-button"
                                                        type="button" @click="onIncrement(key, 1)">+</button>
                                                </div>
                                                <span v-show="errors.has('Quantity')" class="help-block">
                                                    {{ errors.first('Quantity') }}
                                                </span>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-form-group>
                                            <b-button v-if="selConditions[key].description !== 'Default'" size="sm"
                                                v-b-tooltip.hover.top="'Delete Billable Rate'" variant="danger"
                                                @click.stop="deleteCondition(key)" class="mr-1 mt-1">
                                                <em class="fa fa-trash"></em>
                                            </b-button>
                                            <span v-else><i>Default</i></span>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>

                            <div class="mt-4 mb-1 clearfix">
                                <div class="float-left">
                                    <span class="classification-status">Classification Status</span>
                                </div>
                                <div class="float-right">
                                    <small :class="totalRemaining < 0 ? 'help-block' : 'text-muted'">
                                        {{ totalClassified }} / {{ totalToClassify }}
                                    </small>
                                </div>
                            </div>
                            <b-progress :value="(totalClassified / totalToClassify) * 100" class="progress-s"
                                :variant="totalRemaining < 0 ? 'danger' : 'success'" />
                        </b-tab>
                    </b-tabs>
                </div>
            </b-container>
        </b-form>
    </b-modal>
</template>

<script>
// Utils
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { FileUtil } from '@/utils/fileUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { storage } from '@/config/firebase';
import _ from 'lodash';

export default {
    name: 'classify-dispatch-assets',
    components: {
        Loading
    },
    props: {
        allAssetTypesObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            form: {},

            selDispatch: {},
            selAssetType: {},
            selCondition: null,
            selConditions: {},
        
            selDocuments: [null, null, null],
            files: [null, null, null],
            noOfDocuments: 1,

            conditionOptions: [],
            handlingOptions: config.handlingTypes,

            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        title() {
            return `Classify ${this.selAssetType.name}`;
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        remarksRegex() {
            return config.remarksRegex;
        },
        dispatchId() {
            let dispatch = this.selDispatch ? this.selDispatch : {};
            return dispatch.dispatchId ? dispatch.dispatchId : '';
        },
        dispatchNo() {
            let dispatch = this.selDispatch ? this.selDispatch : {};
            return dispatch.dispatchNo ? dispatch.dispatchNo : '';
        },
        totalClassified() {
            let totalClassified = 0;
            if (!_.isEmpty(this.selConditions)) {
                _.forEach(this.selConditions, condition => {
                    const quantity = condition.quantity;
                    totalClassified += parseInt(quantity);
                });
            }
            return totalClassified;
        },
        totalToClassify() {
            return this.selAssetType.actual;
        },
        totalClassifiedPercentage() {
            return (this.totalClassified / this.totalToClassify) * 100;
        },
        totalRemaining() {
            return this.totalToClassify - this.totalClassified;
        }
    },
    mounted() {
        EventBus.$on('onClassifyDispatchAssets', (params) => {
            this.onReset(params);
        });
    },
    methods: {
        getValidationParam(isRequired, regex) {
            return {
                required: isRequired,
                regex: regex,
            };
        },

        // Conditions
        onAddCondition() {
            if (!this.selCondition) {
                this.$toaster.warning('Please select condition to proceed.');
            } else if (this.selCondition && this.selConditions[this.selCondition.id]) {
                this.$toaster.warning('Condition already included on the list')
            } else {
                this.$set(this.selConditions, this.selCondition.id, {
                    condition: this.selCondition.condition,
                    handling: null,
                    quantity: 0,
                    description: this.selCondition.description
                });

                this.selCondition = null;
            }
        },
        deleteCondition(id) {
            this.$delete(this.selConditions, id);
        },
        onIncrement(id, value) {
            const currentQuantity = this.selConditions[id].quantity ? parseInt(this.selConditions[id].quantity) : 0;
            const newQuantity = Math.max(0, currentQuantity + value);
            this.$set(this.selConditions[id], 'quantity', newQuantity);
        },
        isConditionNonNull() {
            return this.selConditions && !_.isEmpty(this.selConditions);
        },

        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            this.isLoading = true;

            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                this.isLoading = false;
                return;
            }

            let isValidAccount = this.validateClassification();
            if (!isValidAccount) {
                this.isLoading = false;
                return;
            }

            await this.handleSubmit();
        },
        validateClassification() {
            let isValid = true;

            if (_.isEmpty(this.form.area)) {
                this.$toaster.warning('Please select an area.');
                isValid = false;
            } else if (!this.hasUploadedDocs()) {
                this.$toaster.warning('Please upload at least 1 supporting document to proceed.');
                isValid = false;
            } else if (this.hasInvalidDocs()) {
                this.$toaster.warning("Please check if all documents uploaded have a file type of PNG or JPG only.");
                isValid = false;
            } else if (this.totalRemaining < 0) {
                this.$toaster.warning('Total classified assets are more than the actual asset count');
                isValid = false;
            } else if (!_.isEmpty(this.selConditions)) {

                let hasMissingHandling = false;
                let conditionWithNoHandling = '';

                for (let id in this.selConditions) {
                    let condition = this.selConditions[id];
                    if (!condition.handling) {
                        conditionWithNoHandling = condition.condition;
                        hasMissingHandling = true;
                        break;
                    }
                }

                if (hasMissingHandling) {
                    this.$toaster.warning(`Please select a handling option for ${conditionWithNoHandling}.`);
                    isValid = false;
                } else if (this.totalClassified !== this.selAssetType.actual) {
                    this.$toaster.warning(`Please classify the remaining assets to their respective conditions.`);
                    isValid = false;
                }
            }

            return isValid;
        },
        hasUploadedDocs() {
            let uploadedDocs = _.filter(this.selDocuments, document => {
                return document && !_.isEmpty(document);
            });
            return _.size(uploadedDocs) >= 1;
        },
        hasInvalidDocs() {
            let invalidDocs = _.filter(this.selDocuments, document => {
                return document !== null && document instanceof File;
            });
            return !_.isEmpty(invalidDocs);
        },

        getClassificationObj() {
            // collection reference
            this.form.collectionName = 'dispatches';
            this.form.collectionId = this.selDispatch.id;
            this.form.dispatchId = this.selDispatch.dispatchId;
            this.form.dispatchNo = this.selDispatch.dispatchNo;

            // asset Type
            this.form.assetType = this.selAssetType.name;
            this.form.assetTypeId = this.selAssetType.id;

            // conditions
            this.form.conditions = Object.values(this.selConditions);

            // documents
            this.form.documents = [];
            for (const document of this.selDocuments) {
                if (document && !_.isEmpty(document)) {
                    let documentObj = document;
                    if (documentObj.isNew) {
                        documentObj = {
                            name: document.name,
                            url: document.url,
                            geoaddress: { latitude: 0.0, longitude: 0.0 },
                        };
                    }
                    this.form.documents.push(documentObj);
                } else {
                    this.form.documents.push(null);
                }
            }

            // timestamps
            this.form.dateUpdated = DateUtil.getCurrentTimestamp();
            this.form.updatedBy = this.loggedUser.id;

            return this.form;
        },
        async handleSubmit() {
            // show loading indicator
            this.isLoading = true;

            try {
                let classification = this.getClassificationObj();
                let { data } = await dispatchApi.saveClassification(
                    classification,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    // upload document forms
                    await this.uploadDocuments(data.classification);

                    this.$toaster.success(`"${this.selAssetType.name} classification was created successfully.`);
                    EventBus.$emit('onCloseSaveClassification', data.classification);
                    this.$refs.modal.hide();

                } else {
                    this.$toaster.error(`Error creating "${this.selAssetType.name}" classification. Please try again.`);
                }
            } catch (error) {
                this.$toaster.error(`Error creating "${this.selAssetType.name}" classification. Please try again.`);
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },

        // Documents
        async uploadDocuments(classification) {
            for (let i = 0; i < this.selDocuments.length; i++) {
               
                const document = this.selDocuments[i];
                if (document && document.isNew && document.file instanceof File) {
                    
                    const result = await this.firebaseUpload(document);
                    if (result.name && result.url) {
                        classification.documents[i].url = result.url;
                        await dispatchApi.saveClassification(classification, this.loggedUser.id, DateUtil.getCurrentTimestamp());
                    }
                }
            }
        },
        async firebaseUpload(document) {
            return new Promise((resolve, reject) => {
                let storageRef = storage.ref(`${document.fbStoragePath}/${document.name}`);
                let task = storageRef.put(document.file);

                task.on(
                    'state_changed',
                    () => {
                        // let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    },
                    (error) => {
                        reject(error);
                    },
                    () => {
                        task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            resolve({
                                name: document.name,
                                url: downloadURL,
                            });
                        });
                    }
                );
            });
        },
        onShowDocument(doc) {
            let url = doc.url;
            let fileName = doc.name;
            EventBus.$emit("onSelectImageView", { url: url, name: fileName });
            this.$bvModal.show("image-view-dialog");
        },
        onSelectDocument(evt, id) {
            const file = evt.target.files[0];

            if (!FileUtil.isValidImgFileType(file)) {
                this.$toaster.error("Invalid File Type: Please import a file in PNG or JPEG format.");
                return;
            }

            const vm = this;
            const url = URL.createObjectURL(file);
            let dimensions = { w: 0, h: 0 };

            const image = new Image();
            image.onload = function () {
                dimensions.w = image.width;
                dimensions.h = image.height;

                if (dimensions.w > 1000 || dimensions.h > 1000) {
                    vm.$toaster.warning("The supporting document width and height shouldn't be greater than 1000 pixels.");
                } else {
                    vm.$set(vm.selDocuments, id, {
                        name: `${vm.selDispatch.dispatchId}_${vm.selAssetType.name}_doc_${id}.jpg`,
                        url: url,
                        file: file,
                        fbStoragePath: 'images/classifications',
                        isNew: true,
                    });
                }
            };
            image.src = url;
        },
        onAddDocument() {
            if (this.noOfDocuments < 3) {
                this.noOfDocuments++;
            } else if (this.noOfDocuments === 3) {
                this.$toaster.warning('You can only add up to 3 documents only');
            }
        },
        onResetDocument(id) {
            this.$set(this.selDocuments, id, null);
            this.$set(this.files, id, null);
        },
        isNonNullDocument(id) {
            return this.selDocuments[id] && !_.isEmpty(this.selDocuments[id]);
        },

        onReset(params) {
            if (params.classification && !_.isEmpty(params.classification)) {
                this.form = { ...params.classification };
            } else {
                this.form = { ...config.classificationDefault };

                this.form.collectionName = 'dispatches';
                this.form.collectionId = this.selDispatch.id;
                this.form.dispatchId = this.selDispatch.dispatchId;
                this.form.dispatchNo = this.selDispatch.dispatchNo;

                // timestamps
                this.form.dateCreated = DateUtil.getCurrentTimestamp();
                this.form.createdBy = this.loggedUser.id;
            }

            this.selDispatch = params.dispatch;
            this.selAssetType = params.assetType;

            // init selected conditions
            this.initConditionOptions();

            this.selConditions = {};
            if (_.isEmpty(this.form.conditions)) {
                this.preloadActiveConditions();
            } else {
                for (const condition of this.form.conditions) {
                    this.$set(this.selConditions, condition.condition.toLowerCase(), { ...condition });
                }
            }

            // init selected documents
            this.noOfDocuments = 0;
            if (_.isEmpty(this.form.documents)) {
                this.selDocuments = [null, null, null];
                this.files = [null, null, null];
                this.noOfDocuments = 1;
            } else {
                this.selDocuments = [];
                for (const document of this.form.documents) {
                    if (document && ValidationUtil.isValidURL(document.url)) {
                        this.selDocuments.push(document);
                    } else {
                        this.selDocuments.push(null);
                    }
                    this.noOfDocuments++;
                }
            }
        },
        initConditionOptions() {
            this.conditionOptions = [{ value: null, text: ' - Please select - ' }];

            const assetTypeObj = this.allAssetTypesObj[this.selAssetType.id];
            const conditions = assetTypeObj.conditions;

            for (const condition of conditions) {
                if (condition.isActive) {
                    this.conditionOptions.push({ value: condition, text: condition.condition });
                }
            }
        },
        preloadActiveConditions() {
            const assetTypeObj = this.allAssetTypesObj[this.selAssetType.id];
            const conditions = assetTypeObj.conditions;

            for (const condition of conditions) {
                if (condition.isActive) {
                    this.$set(this.selConditions, condition.id, {
                        condition: condition.condition,
                        description: condition.description,
                        handling: AssetTypeUtil.getDefaultHandling(condition.id),
                        quantity: 0,
                    });
                }
            }
        },

        // UTILS
        getSource() {
            let source = this.selDispatch && this.selDispatch.source ? this.selDispatch.source : {};
            return DispatchUtil.getCompanyLocationDisplay(source);
        },
        getDestination() {
            let destination = this.selDispatch && this.selDispatch.destination ? this.selDispatch.destination : {};
            return DispatchUtil.getCompanyLocationDisplay(destination);
        },
    },
    beforeDestroy() {
        EventBus.$off('onClassifyDispatchAssets');
    }
}

</script>

<style scoped>
.fa.fa-map-marker {
    margin-right: 8px;
}

.add-condition {
    margin-top: 32px;
    margin-left: 10px;
}

.classification-items {
    height: 100px;
    width: 180px;
    color: white;
    border-radius: 5px;
    margin-right: 1em;
}

.decrement-button {
    width: 35px;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.increment-button {
    width: 35px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.orange {
    background-color: #E48F01;
}

.blue {
    background-color: #122B91;
}

.item-count {
    font-size: 30px;
}

.item-label {
    font-size: 16px;
}

.vertical-center {
    margin-top: 15px;
    margin-left: 20px;
}

.label-header {
    font-weight: bold;
}

.document-file {
    font-weight: bold;
    margin-top: -1em;
    margin-bottom: 0.5em;
    cursor: pointer;
}

.document-file i {
    color: #E48F01;
    margin-right: 0.5em;
}

.classification-status {
    font-size: 14px;
    font-weight: bold;
}

.delete-document {
    margin-top: 32px;
    margin-left: 5px;
}
</style>